import * as React from "react";
import { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Box,
  Stack,
  Heading,
  Text,
  Image,
  Button,
  useColorModeValue,
  Container,
  useToast,
  Flex,
  SimpleGrid,
  StackDivider,
  SpaceProps,
  HStack,
  Tag,
  Link,
  Grid,
  GridItem,
  ButtonGroup,
  Select,
  useDisclosure,
  Spacer
} from "@chakra-ui/react";
import { PageSlideFade } from "../components/page-transitions";
import { apiHost, baseUrl } from "utils/Cons/Constants";
import axios from "axios";
import { Helmet } from "react-helmet";
import headerBg from "../assets/images/header-background.jpg";
import cloudsBg from "../assets/images/cloud-background.png";
import parser from "html-react-parser";
import Masonry from "react-masonry-css";
import "../style/style.css";
import { MotionBox } from "../components/motion";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon, LatLng } from 'leaflet';
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import MediaBlock from "../components/media-block";
import FavoritesBlock from "../components/favorites-block";
import ImageBlock from "../components/image-block";
import CrowdBlock from "../components/crowd-block";
import MapBlock from "components/map-block";
import CTABlock from "components/cta-block";
import ImagesBlock from "components/images-block";
import ImagesGridBlock from "components/images-grid-block";
import TabsBlock from "components/tabs-block";
import SlidesBlock from "components/slides-block";

const markdownTheme = {
  p: props => {
    const { children } = props;
    return (
      <Text mb={2} fontSize={'0.8em'}>
        {children}
      </Text>
    );
  },
};



const Experience = ({ match }) => {
  const toast = useToast();
  const bgColor = useColorModeValue('white', 'gray.900');
  const headingColor = useColorModeValue('gray.700', 'white');

  const [destination, setDestination] = useState({});
  const [loading, setLoading] = useState(true);

  const [grid, setGrid] = useState(true);
  const [breakpointColumns, setBreakpointColumns] = useState({});

  const [searchValues, setSearchValues] = useState({});
  const [destinations, setDestinations] = useState([]);

  const [selectedDestination, setSelectedDestination] = useState({});

  const initialMarkers = [];
  const [markers, setMarkers] = useState(initialMarkers);

  const position = [40.7880456, -77.870124];

  // drawers
  const { isOpen, onOpen, onClose } = useDisclosure()
  const firstField = useRef()

  const {
    isOpen: isFilterOpen,
    onOpen: onFilterOpen,
    onClose: onFilterClose
  } = useDisclosure()

  const breakpointColumnsObj = {
    default: 3,
    1300: 2,
    900: 1
  };

  const breakpointColumnsListObj = {
    default: 1
  };

  const LocationMarkers = () => {
    return (
      <React.Fragment>
        {markers.map(marker => <Marker
          position={marker.location}
          icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
          eventHandlers={{
            click: (e) => {
              setSelectedDestination(marker.data);
              onOpen();
            },
          }}>
          <Popup>
            <Link href={`/d/${marker.data.slug}`}>{marker.data.name}</Link><br />
            {marker.data.address}<br />
            {marker.data.city}, {marker.data.state} {marker.data.zip}<br />
            <Link href={`tel:+1${marker.data.phone}`}>{marker.data.phone}</Link>
          </Popup>
        </Marker>)}
      </React.Fragment>
    );
  }

  const getExperience = () => {
    let data = {};
    axios.get(`${apiHost}/experiences/get/${match.params.id}`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setDestination(data);

          if (data.destinations) {
            let geomarkers = [];
            data.destinations.forEach(marker => {
              geomarkers.push({ data: marker, location: new LatLng(marker.location_latitude, marker.location_longitude) })
            });
            setMarkers(geomarkers);
          }

          setLoading(false);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const showToast = (message, status) => {
    toast({
      position: "bottom",
      description: message,
      status: status,
      duration: 5000,
      isClosable: true
    });
  };

  const getDestinations = (values = null) => {
    let data = [];
    axios.post(`${apiHost}/destinations/search`, values)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setDestinations(data);

          let geomarkers = [];
          data.forEach(marker => {
            geomarkers.push({ data: marker, location: new LatLng(marker.location_latitude, marker.location_longitude) })
          });
          setMarkers(geomarkers);

          setLoading(false);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const handleReset = () => {
    document.getElementById("category").value = "";
    document.getElementById("experience").value = "";
    document.getElementById("activity").value = "";
  };

  const handleDestinationClick = (destination) => {
    setSelectedDestination(destination);
    onOpen();
  }

  const sortClick = function (order) {
    let values = searchValues;
    values.order = order;
    getDestinations(values);
  }

  const onGridList = function (breakpointColumns) {
    setBreakpointColumns(breakpointColumns)
    if (breakpointColumns.default == 1) {
      setGrid(false);
    } else {
      setGrid(true);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getExperience();
  }, []);

  return (
    <PageSlideFade>
      <Helmet>
        <title>{destination.name}</title>
        <meta name="description" content={destination.meta_description} />
      </Helmet>
      {destination.main_image_url != null ? (
        <Box className="home-header-container" backgroundImage={`url(${destination.main_image_url})`} backgroundPosition="center center" backgroundSize="cover" minHeight="60dvh">
          <Container maxW={'5xl'}>

          </Container>
        </Box>
      ) : null}
      <Box bg="#15315A">
        <Container maxW={'5xl'}>
          <Grid templateColumns='repeat(6, 1fr)'>
            <GridItem colSpan={{ base: 12, md: 6 }}>
              <Text
                textAlign="left"
                fontFamily="american"
                fontWeight={500}
                fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
                lineHeight={'100%'}
                color="#BDECDE"
                textShadow="0px 0px 5px #000"
              >{destination.name}</Text>
            </GridItem>
          </Grid>
        </Container>
      </Box>
      <Box minHeight="25vh" bg="#8FBD99">
        <Container maxW={'6xl'}>
          <Box pt={10} pb={10}>
            {destination.experience}
          </Box>
        </Container>
      </Box>

      {destination.content_blocks?.map((block, index) => (
        <Box key={`block-${index}`}>
          {
            {
              'anomaly.extension.favorites_block_block': <FavoritesBlock page={destination} block={block} />,
              'anomaly.extension.media_block_block': <MediaBlock page={destination} block={block} />,
              'anomaly.extension.image_block_block': <ImageBlock page={destination} block={block} />,
              'anomaly.extension.crowd_block_block': <CrowdBlock page={destination} block={block} />,
              'anomaly.extension.map_block_block': <MapBlock page={destination} block={block} />,
              'anomaly.extension.cta_block_block': <CTABlock page={destination} block={block} />,
              'anomaly.extension.images_block_block': <ImagesBlock page={destination} block={block} />,
              'anomaly.extension.images_grid_block_block': <ImagesGridBlock page={page} block={block} />,
              'anomaly.extension.tabs_block_block': <TabsBlock page={destination} block={block} />,
              'anomaly.extension.slides_block_block': <SlidesBlock page={destination} block={block} />,
            }[block.extension]
          }
        </Box>
      ))}

      <Box bg="white">
        <Box pt={10} display="none">
          <SimpleGrid columns={3}>
            <Box textAlign="left">
              <Button onClick={() => onFilterOpen()} p={5}>Filter</Button>
            </Box>
            <Box display={{ base: "none", md: "block" }}>
              <ButtonGroup spacing='2'>
                <Button onClick={() => onGridList(breakpointColumnsObj)} p={5}>Grid</Button>
                <Button onClick={() => onGridList(breakpointColumnsListObj)} p={5}>List</Button>
              </ButtonGroup>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="right" >
              <HStack marginLeft="auto" mb={5} mr={5}>
                <Spacer />
                <Text w="100px">Sort By</Text>
                <Select
                  onChange={(e) => {
                    sortClick(e.target.value);
                  }} w="200px">
                  <option value="updated" selected>Last Updated</option>
                  <option value="name">Business Name</option>
                </Select>
              </HStack>
            </Box>
          </SimpleGrid>
        </Box>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Box p={5}>

            {loading ? null : (
              <Masonry breakpointCols={breakpointColumns}
                className="masonry-grid"
                columnClassName="masonry-grid_column">

                {destination.destinations?.map((destination, index) => (
                  <Box
                    w={'100%'}
                    bg="#8FBD99"
                    boxShadow={'0 0 10px 2px #ccc'}
                    rounded={'md'}
                    _hover={{
                      bg: '#E0CEBE'
                    }}
                    d="inline-block"
                    mb={5}
                    className="masonry"
                  >
                    <Box>
                      <Box position="relative">
                        {destination.main_img && (
                          <Link href={`/d/${destination.slug}`}>
                            <Image
                              src={`${destination.main_img}`}
                              layout={'fill'}
                              w={'100%'}
                              rounded={'md'}
                              borderBottomRadius={"0"}
                            />
                          </Link>
                        )}
                        {destination.bd_lowest_rate && (
                          <Link
                            href={destination.reservations_link}
                            target="_blank"
                            fontWeight={'bold'}
                            fontSize="15px"
                            color="white"
                            whiteSpace={'nowrap'}
                            bg="#8A89E5"
                            borderTopStartRadius={"0.375rem"}
                            borderBottomStartRadius={"0.375rem"}
                            borderTopEndRadius={"0.375rem"}
                            borderBottomEndRadius={"0.375rem"}
                            p={2}
                            position="absolute"
                            bottom="10px"
                            right="10px"
                          ><small><em>starting at</em></small><br />${destination.bd_lowest_rate}</Link>
                        )}
                      </Box>
                      <Box position="relative" p={2}>
                        <Link href={`/d/${destination.slug}`}>
                          <Heading
                            color={headingColor}
                            fontSize={'lg'}
                            fontFamily={'titling'}
                            pt={2}
                            lineHeight="115%"
                            pb={2}>
                            {destination.name}
                          </Heading>
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                ))
                }
              </Masonry>
            )}

          </Box>
          <Box>
            <Box sx={{ position: '-webkit-sticky', /* Safari */ position: 'sticky', top: '0px', }}>
              <MapContainer center={position} zoom={10} scrollWheelZoom={false} style={{ minHeight: '100vh' }}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarkers />
              </MapContainer>
            </Box>
          </Box>
        </SimpleGrid>

      </Box>

    </PageSlideFade>
  );
};

export default Experience;
