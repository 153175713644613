import React, { useState, useEffect } from "react";
import parser from "html-react-parser";
import {
    Box,
    Stack,
    Heading,
    Text,
    Image,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Center,
    SimpleGrid,
    FormControl,
    FormErrorMessage,
    Input,
    useToast
} from "@chakra-ui/react";
import { FaFacebook, FaLinkedin, FaInstagram, FaYoutube, FaTwitter, FaPinterest, FaEnvelope, FaClipboard } from "react-icons/fa";
import { Field, Formik } from "formik";
import axios from "axios";
import { apiHost } from "utils/Cons/Constants";
import Confetti from 'react-confetti';
const ShareModal = ({ page, path = '' }) => {
    const token = localStorage.getItem("token");

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [runConfetti, setRunConfetti] = useState(false);

    const [shareUrl, ] = useState("https://happyvalley.com/" + path + page.slug);

    const toast = useToast();

    const copyToClipboard = () => {
        navigator.clipboard.writeText(shareUrl);
        alert("Link copied to clipboard!");
    };

    const shareLink = (values, actions) => {
        let data = {
          url: values.link,
          email: values.email,
        };
    
        axios.post(`${apiHost}/api/share/link`, data, {
          headers: { "Authorization": "Bearer " + token }
        })
          .then(res => {
            data = res.data;
            console.log(data);
    
            showToast("Link shared with " + values.email, 'success');

            setRunConfetti(true);
            setTimeout(() => {
                setRunConfetti(false);
            }, 15000);

            actions.setSubmitting(false);
            actions.resetForm();
    
            return res;
          })
          .catch(err => {
            console.log(err);
            actions.setSubmitting(false);
            showToast(err.response.data.message, 'error');
          });
    
        return data;
      };

      const showToast = (message, status) => {
        toast({
          position: "bottom",
          description: message,
          status: status,
          duration: 5000,
          isClosable: true
        });
      };

    useEffect(() => {
        
    }, []);

    return (
        <Box>
            <Button onClick={onOpen} borderTopStartRadius={15} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" color="white" bg="#8A89E5" w="100%">Share Link</Button>
            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Share this HappyValley.com link?</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Center p={8}>
                            <Stack spacing={2} align={'center'} maxW={'md'} w={'full'}>
                                <Box mb={5}>
                                    happyvalley.com/{path}{page.slug}
                                </Box>

                                <Button
                                    w={'full'}
                                    borderBottomStartRadius={15}
                                    borderRadius={15}
                                    fontSize="0.7em"
                                    color="white"
                                    bg="#8A89E5"
                                    leftIcon={<FaFacebook />}
                                    as="a"
                                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`}
                                    target="_blank"
                                >
                                    <Center>
                                        <Text>Share on Facebook</Text>
                                    </Center>
                                </Button>

                                <Button
                                    w={'full'}
                                    borderBottomStartRadius={15}
                                    borderRadius={15}
                                    fontSize="0.7em"
                                    color="white"
                                    bg="#8A89E5"
                                    leftIcon={<FaInstagram />}
                                    onClick={copyToClipboard}
                                >
                                    <Center>
                                        <Text>Share on Instagram</Text>
                                    </Center>
                                </Button>

                                <Button
                                    w={'full'}
                                    borderBottomStartRadius={15}
                                    borderRadius={15}
                                    fontSize="0.7em"
                                    color="white"
                                    bg="#8A89E5"
                                    leftIcon={<FaPinterest />}
                                    as="a"
                                    href={`https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(shareUrl)}`}
                                    target="_blank"
                                >
                                    <Center>
                                        <Text>Share on Pinterest</Text>
                                    </Center>
                                </Button>

                                <Button
                                    w={'full'}
                                    borderBottomStartRadius={15}
                                    borderRadius={15}
                                    fontSize="0.7em"
                                    color="white"
                                    bg="#8A89E5"
                                    leftIcon={<FaTwitter />}
                                    as="a"
                                    href={`https://x.com/intent/post?url=${encodeURIComponent(shareUrl)}`}
                                    target="_blank"
                                >
                                    <Center>
                                        <Text>Share on X</Text>
                                    </Center>
                                </Button>

                                <Button
                                    w={'full'}
                                    borderBottomStartRadius={15}
                                    borderRadius={15}
                                    fontSize="0.7em"
                                    color="white"
                                    bg="#8A89E5"
                                    leftIcon={<FaLinkedin />}
                                    as="a"
                                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`}
                                    target="_blank"
                                >
                                    <Center>
                                        <Text>Share on LinkedIn</Text>
                                    </Center>
                                </Button>

                                <Button
                                    w={'full'}
                                    borderBottomStartRadius={15}
                                    borderRadius={15}
                                    fontSize="0.7em"
                                    color="white"
                                    bg="#8A89E5"
                                    leftIcon={<FaYoutube />}
                                    onClick={copyToClipboard}
                                >
                                    <Center>
                                        <Text>Share on YouTube</Text>
                                    </Center>
                                </Button>

                                <Button
                                    w={'full'}
                                    borderBottomStartRadius={15}
                                    borderRadius={15}
                                    fontSize="0.7em"
                                    color="white"
                                    bg="#8A89E5"
                                    leftIcon={<FaClipboard />}
                                    onClick={copyToClipboard}
                                >
                                    <Center>
                                        <Text>Copy Link</Text>
                                    </Center>
                                </Button>

                                <Formik
                                    enableReinitialize
                                    initialValues={{ email: "", link: shareUrl }}
                                    onSubmit={(values, actions) => {
                                        if (token) {
                                            shareLink(values, actions);
                                        } else {
                                            localStorage.setItem("shareLink", values.link);
                                            localStorage.setItem("shareLinkEmail", values.email);
                                            window.location.href = "/profile";
                                        }
                                    }}
                                >
                                    {({ values, handleChange, handleSubmit, isSubmitting }) => {
                                        return (
                                            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                                                <FormControl pt={2}>
                                                    <Field
                                                        name="email"

                                                    >
                                                        {({ field, form }) => (
                                                            <FormControl
                                                                isInvalid={
                                                                    form.errors.email && form.touched.email
                                                                }
                                                            >
                                                                <FormErrorMessage mt="-15px">
                                                                    {form.errors.email}
                                                                </FormErrorMessage>
                                                                <Input
                                                                    {...field}
                                                                    id="email"
                                                                    type="email"
                                                                    placeholder="Your or your friend's email address"
                                                                    _placeholder={{ color: "#A1C7C6" }}
                                                                    bg="#19325B"
                                                                    color="#A1C7C6"
                                                                    border="0"
                                                                    value={values.email}
                                                                    required={true}
                                                                    onChange={handleChange}
                                                                    borderBottomStartRadius={0}
                                                                    borderBottomEndRadius={0}
                                                                />
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </FormControl>
                                                <Button w={'full'} isLoading={isSubmitting} loadingText="Emailing Link" borderTopStartRadius={0} borderTopEndRadius={0} borderBottomStartRadius={15} borderRadius={15} fontSize="0.7em" color="white" bg="#8A89E5" leftIcon={<FaEnvelope />} type="submit">
                                                    <Center>
                                                        <Text>Email Link</Text>
                                                    </Center>
                                                </Button>
                                            </form>
                                        );
                                    }}
                                </Formik>

                            </Stack>
                        </Center>

                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
                <Confetti run={runConfetti} recycle={false} numberOfPieces={500} style={{ width: '100%', height: '125vh', zIndex: 999999 }} />
            </Modal>
        </Box>
    )
};

export default ShareModal;