import * as React from "react";
import { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Box,
  Stack,
  Heading,
  Text,
  Image,
  Button,
  useColorModeValue,
  Container,
  useToast,
  Flex,
  SimpleGrid,
  StackDivider,
  SpaceProps,
  HStack,
  Tag,
  Link,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  FormErrorMessage,
  ButtonGroup,
  Grid,
  GridItem,
  Spacer,
  Show
} from "@chakra-ui/react";
import { PageSlideFade } from "../components/page-transitions";
import { apiHost, baseUrl } from "utils/Cons/Constants";
import axios from "axios";
import { Helmet } from "react-helmet";
import { FaAccessibleIcon, FaBullseye, FaSearch, FaVolumeUp } from "react-icons/fa";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon, LatLng } from 'leaflet';
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { MotionBox } from "../components/motion";
import cloudsBg from "../assets/images/cloud-background.png";
import { Field, Formik } from "formik";
import Masonry from "react-masonry-css";
import "../style/style.css";
import useSound from "use-sound";
import parser from "html-react-parser";
import Slider from "react-slick";
import MediaBlock from "../components/media-block";
import FavoritesBlock from "../components/favorites-block";
import ImageBlock from "../components/image-block";
import CrowdBlock from "../components/crowd-block";
import MapBlock from "../components/map-block";
import CTABlock from "components/cta-block";
import ImagesBlock from "components/images-block";
import ImagesGridBlock from "components/images-grid-block";
import FpAdBlock from "components/fp-ad-block";
import FcAdBlock from "components/fc-ad-block";
import FeaturedAdBlock from "components/featured-ad-block";

const markdownTheme = {
  p: props => {
    const { children } = props;
    return (
      <Text mb={2} fontSize={'0.8em'}>
        {children}
      </Text>
    );
  },
};

const Destinations = (props) => {
  const toast = useToast();

  const [grid, setGrid] = useState(true);
  const [breakpointColumns, setBreakpointColumns] = useState({});

  const [searchValues, setSearchValues] = useState({});

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose
  } = useDisclosure();

  const [destinations, setDestinations] = useState([]);
  const [loading, setLoading] = useState(true);

  const [featuredDestinations, setFeaturedDestinations] = useState([]);

  const [experiencesloading, setExperiencesLoading] = useState(false);
  const [experiences, setExperiences] = useState([]);

  const [activitiesloading, setActivitiesLoading] = useState(false);
  const [activities, setActivities] = useState([]);

  const [categoriesloading, setCategoriesLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const [selectedDestination, setSelectedDestination] = useState({});

  const [page, setPage] = useState({ fields: {} });

  const bgColor = useColorModeValue('white', 'gray.900');
  const headingColor = useColorModeValue('gray.700', 'white');

  const position = [40.7880456, -77.870124];

  /*
  const [play] = useSound(sound, {
    volume: 1
  });
  */

  // drawers
  const { isOpen, onOpen, onClose } = useDisclosure()
  const firstField = useRef()

  const {
    isOpen: isFilterOpen,
    onOpen: onFilterOpen,
    onClose: onFilterClose
  } = useDisclosure()

  const initialMarkers = [];
  const [markers, setMarkers] = useState(initialMarkers);

  const breakpointColumnsObj = {
    default: 3,
    1300: 2,
    900: 1
  };

  const breakpointColumnsListObj = {
    default: 1
  };

  const settingsSlickEvents = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const LocationMarkers = () => {
    return (
      <React.Fragment>
        {markers.map(marker => <Marker
          position={marker.location}
          icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}
          eventHandlers={{
            click: (e) => {
              setSelectedDestination(marker.data);
              onOpen();
            },
          }}>
        </Marker>)}
      </React.Fragment>
    );
  }

  const getDestinations = (values = null) => {
    let data = [];
    axios.post(`${apiHost}/destinations/search`, values)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setDestinations(data);
          setFeaturedDestinations(data.slice(0, 10));

          let geomarkers = [];
          data.forEach(marker => {
            geomarkers.push({ data: marker, location: new LatLng(marker.location_latitude, marker.location_longitude) })
          });
          setMarkers(geomarkers);

          setLoading(false);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getCategories = () => {
    let data = [];
    axios.get(`${apiHost}/categories/list`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setCategories(data);
          setCategoriesLoading(false);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getExperiences = () => {
    let data = [];
    axios.get(`${apiHost}/experiences/list`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setExperiences(data);
          setExperiencesLoading(false);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getActivities = () => {
    let data = [];
    axios.get(`${apiHost}/activities/list`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setActivities(data);
          setActivitiesLoading(false);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const getPage = () => {
    let data = [];
    axios.get(`${apiHost}/page/get/destinations`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          data.number_id = data.id.toString();
          setPage(data);
          console.log('entry-id', data.number_id);
          console.log('entry-slug', data.slug);
        }
        return res;
      })
      .catch(err => console.log(err));

    return data;
  };

  const showToast = (message, status) => {
    toast({
      position: "bottom",
      description: message,
      status: status,
      duration: 5000,
      isClosable: true
    });
  };

  const showNotification = (message, status) => {
    toast({
      position: "bottom",
      description: message,
      status: status,
      duration: 10000,
      isClosable: true
    });
  };

  const handleReset = () => {
    document.getElementById("category").value = "";
    document.getElementById("experience").value = "";
    document.getElementById("activity").value = "";
  };

  const handleDestinationClick = (destination) => {
    setSelectedDestination(destination);
    onOpen();
  }

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  const sortClick = function (order) {
    let values = searchValues;
    values.order = order;
    getDestinations(values);
  }

  const onGridList = function (breakpointColumns) {
    setBreakpointColumns(breakpointColumns)
    if (breakpointColumns.default == 1) {
      setGrid(false);
    } else {
      setGrid(true);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getPage();
    getDestinations();
    getExperiences();
    getActivities();
    getCategories();
    setBreakpointColumns(breakpointColumnsObj);
    //showNotification("Search for destinations notification", "info");
    /*
    setTimeout(() => {
      onModalOpen();
    }, 2000);
    */
  }, []);

  return (
    <PageSlideFade>
      <Helmet>
        <title>{page.meta_title}</title>
        <meta name="description" content={page.meta_description} />
      </Helmet>
      <Box className="home-header-container" backgroundImage={`url(${page.header_image_url})`} backgroundPosition="center center" backgroundSize="cover" minHeight="60vh">
        <Container maxW={'6xl'}>
          <Box display="flex" flexDirection="column" flex="1" minHeight="60vh">
            <Box mt={'auto'}>
              <Heading
                as={'h1'}
                fontFamily="american"
                fontWeight={500}
                fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                color="white"
                lineHeight={'100%'}
                textAlign="left"
                textShadow="0px 0px 5px #000"
              >
                {page.title} {' '}
              </Heading>
            </Box>
          </Box>
        </Container>
      </Box>
      {page.entry && page.entry.subtitle ? (
        <Box minHeight="25vh" bg="#8FBD99">
          <Container maxW={'6xl'}>
            <Box pt={10} pb={10}>
              <Text fontSize="3xl" color="white" textAlign="left">
                {page.entry.subtitle}
              </Text>
            </Box>
          </Container>
        </Box>
      ) : null}
      {page.fields.content_blocks?.map((block, index) => (
        <Box key={`block-${index}`}>
          {
            {
              'anomaly.extension.favorites_block_block': <FavoritesBlock page={page} block={block} />,
              'anomaly.extension.media_block_block': <MediaBlock page={page} block={block} />,
              'anomaly.extension.image_block_block': <ImageBlock page={page} block={block} />,
              'anomaly.extension.crowd_block_block': <CrowdBlock page={page} block={block} />,
              'anomaly.extension.map_block_block': <MapBlock page={page} block={block} />,
              'anomaly.extension.cta_block_block': <CTABlock page={page} block={block} />,
              'anomaly.extension.images_block_block': <ImagesBlock page={page} block={block} />,
              'anomaly.extension.images_grid_block_block': <ImagesGridBlock page={page} block={block} />,
              'anomaly.extension.fp_ad_block_block': <FpAdBlock page={page} block={block} />,
              'anomaly.extension.fc_ad_block_block': <FcAdBlock page={page} block={block} />,
              'anomaly.extension.featured_ad_block_block': <FeaturedAdBlock page={page} block={block} />,
            }[block.extension]
          }
        </Box>
      ))}
      <Box bg="white">
        <Box>
          <div class="events-header">
            {page.entry && page.entry.featured_slides_title ? (
              <Heading
                as={'h2'}
                fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                lineHeight={'110%'}
                pt={10}>
                {page.entry.featured_slides_title}
              </Heading>
            ) : null}
          </div>

          <Box p={20}>
            <Slider {...settingsSlickEvents} className="events-slider">

              {featuredDestinations?.map((destination, index) => (
                <div>
                  <Box bg="#8FBD99" borderRadius={10} m={3}>
                    <Link href={`/d/${destination.slug}`}>
                      <Box position="relative">
                        <Image src={destination.main_img} borderTopRadius={10} w="100%" />
                        {destination.bd_lowest_rate && (
                          <Link
                            href={destination.reservations_link}
                            target="_blank"
                            fontWeight={'bold'}
                            fontSize="15px"
                            color="white"
                            whiteSpace={'nowrap'}
                            bg="#8A89E5"
                            borderTopStartRadius={"0.375rem"}
                            borderBottomStartRadius={"0.375rem"}
                            borderTopEndRadius={"0.375rem"}
                            borderBottomEndRadius={"0.375rem"}
                            p={2}
                            position="absolute"
                            bottom="10px"
                            right="10px"
                          ><small><em>starting at</em></small><br />${destination.bd_lowest_rate}</Link>
                        )}
                      </Box>
                      <Box p={5}>
                        <Heading fontSize="lg" fontFamily="titling" textAlign="center">{destination.name}</Heading>
                      </Box>
                    </Link>
                  </Box>
                </div>
              ))}

            </Slider>
          </Box>

        </Box>

      </Box>
      <Box bg="white">
        <Box>
          <SimpleGrid columns={3}>
            <Box textAlign="left">
              <Button onClick={() => onFilterOpen()} p={5}>Filter</Button>
            </Box>
            <Box display={{ base: "none", md: "block" }}>
              <ButtonGroup spacing='2'>
                <Button onClick={() => onGridList(breakpointColumnsObj)} p={5}>Grid</Button>
                <Button onClick={() => onGridList(breakpointColumnsListObj)} p={5}>List</Button>
              </ButtonGroup>

            </Box>
            <Box display="flex" flexDirection="row" alignItems="right" >
              <HStack marginLeft="auto" mb={5} mr={5}>
                <Spacer />
                <Text w="100px" textAlign="right">Sort By</Text>
                <Select
                  onChange={(e) => {
                    sortClick(e.target.value);
                  }} w={{ base: "120px", md: "200px" }}>
                  <option value="updated" selected>Last Updated</option>
                  <option value="name">Business Name</option>
                </Select>
              </HStack>
            </Box>
          </SimpleGrid>
        </Box>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} mt={8}>
          <Box p={5}>
            {loading ? null : (
              <Masonry breakpointCols={breakpointColumns}
                className="masonry-grid"
                columnClassName="masonry-grid_column">

                {destinations?.map((destination, index) => (
                  <Box
                    w={'100%'}
                    bg="#8FBD99"
                    boxShadow={'0 0 10px 2px #ccc'}
                    rounded={'md'}
                    _hover={{
                      bg: '#E0CEBE'
                    }}
                    d="inline-block"
                    mb={5}
                    className="masonry"
                  >
                    <Box
                    >
                      {destination.main_img && (
                        <Box position="relative"
                        >
                          <Link onClick={() => handleDestinationClick(destination)}>
                            <Image
                              src={`${destination.main_img}`}
                              layout={'fill'}
                              w={'100%'}
                              rounded={'md'}
                              borderBottomRadius={"0"}
                            />
                          </Link>
                          {destination.bd_lowest_rate && (
                            <Link
                              href={destination.reservations_link}
                              target="_blank"
                              fontWeight={'bold'}
                              fontSize="15px"
                              color="white"
                              whiteSpace={'nowrap'}
                              bg="#8A89E5"
                              borderTopStartRadius={"0.375rem"}
                              borderBottomStartRadius={"0.375rem"}
                              borderTopEndRadius={"0.375rem"}
                              borderBottomEndRadius={"0.375rem"}
                              p={2}
                              position="absolute"
                              bottom="10px"
                              right="10px"
                            ><small><em>starting at</em></small><br />${destination.bd_lowest_rate}</Link>
                          )}
                        </Box>
                      )}
                      <Box position="relative" p={2}>
                        <Link onClick={() => handleDestinationClick(destination)}>
                          <Heading
                            color={headingColor}
                            fontSize={'lg'}
                            fontFamily={'titling'}
                            pt={2}
                            lineHeight="115%"
                            pb={2}>
                            {destination.name}
                          </Heading>
                          <Text color={'#B58455'}>
                            {destination.d_experiences?.map((exp, index) => (
                              <Link href={`/experiences/${exp.slug}`} mr={5}>{exp.name}</Link>
                            ))
                            }
                          </Text>
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                ))
                }
              </Masonry>
            )}

          </Box>
          <Box>
            <Box sx={{ position: '-webkit-sticky', /* Safari */ position: 'sticky', top: '0px', }}>
              <MapContainer center={position} zoom={12} scrollWheelZoom={false} style={{ minHeight: '100vh' }}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarkers />
              </MapContainer>
            </Box>
          </Box>
        </SimpleGrid>

        <FeaturedAdBlock page={page} block={null} />
      </Box>

      <Drawer
        isOpen={isOpen}
        size="lg"
        placement="left"
        closeOnOverlayClick={true}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent motionProps={{
          initial: 'none',
          animate: 'none',
          exit: 'none',
        }}>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            <Heading pb={0}>{selectedDestination.name}</Heading>
          </DrawerHeader>

          <DrawerBody>
            <Stack spacing="24px">
              <Center>
                {selectedDestination.main_img && (
                  <Box
                    backgroundImage={`${selectedDestination.main_img}`}
                    backgroundSize="cover"
                    backgroundPosition="center center"
                    height="300px"
                    width={'100%'}
                    borderBottomStartRadius={'md'}
                  />
                )}
              </Center>

              <Box position="relative">
                {selectedDestination.bd_lowest_rate && (
                  <Link
                    href={selectedDestination.reservations_link}
                    target="_blank"
                    fontWeight={'bold'}
                    fontSize="15px"
                    color="white"
                    whiteSpace={'nowrap'}
                    bg="#8A89E5"
                    borderTopStartRadius={"0.375rem"}
                    borderBottomStartRadius={"0.375rem"}
                    borderTopEndRadius={"0.375rem"}
                    borderBottomEndRadius={"0.375rem"}
                    p={2}
                    position="absolute"
                    bottom="35px"
                    right="10px"
                    textAlign="center"
                  ><small><em>starting at</em></small><br />${selectedDestination.bd_lowest_rate}</Link>
                )}
              </Box>

              {selectedDestination.content != null && (
                <Box className="content-block">{parser(selectedDestination.content)}</Box>
              )}

              <Box>
                <MapContainer center={[selectedDestination.location_latitude, selectedDestination.location_longitude]} zoom={16} scrollWheelZoom={false} style={{ minHeight: '250px' }}>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker
                    position={[selectedDestination.location_latitude, selectedDestination.location_longitude]}
                    icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}>
                    <Popup closeButton={false}>
                      {selectedDestination.address}
                    </Popup>
                  </Marker>
                </MapContainer>
              </Box>

              <Stack>
                <Center><Text>{selectedDestination.address}</Text></Center>
                <Center><Link href={`tel:+1${selectedDestination.phone}`}>{selectedDestination.phone}</Link></Center>
              </Stack>

            </Stack>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px" justifyContent="flex-end">
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            {selectedDestination.package == 'disabled' ? (
              <Button mr={3} leftIcon={<FaVolumeUp />} onClick={() => play()}>Destination Audio</Button>
            ) : null}
            {selectedDestination ? (
              <Show above='sm'>
                <Button onClick={()=> { localStorage.setItem("selectedDestination", selectedDestination.id); window.location.href='/profile'; }} mr={3}>Add to Itinerary</Button>
              </Show>
            ) : null}
            {selectedDestination ? (
              <Button as={Link} href={`/d/${selectedDestination.slug}`}>More Info</Button>
            ) : null}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Modal
        isOpen={isModalOpen}
        onClose={() => void 0}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent borderRadius="2xl" mx="4" mt={40}>
          <ModalBody>
            <Stack
              maxW="xs"
              mx="auto"
              py={{ base: '12', md: '16' }}
              spacing={{ base: '6', md: '10' }}
            >
              <Stack spacing="3" textAlign="center">
                <Text fontSize="lg">Enter your email below &amp; get</Text>
                <Text
                  color={useColorModeValue('blue.500', 'blue.200')}
                  fontWeight="extrabold"
                  fontSize={{ base: '5xl', md: '6xl' }}
                  textTransform="uppercase"
                  transform="scale(1.2)"
                >
                  20% off
                </Text>
                <Text fontSize="lg">
                  <Box as="span" whiteSpace="nowrap" fontWeight="bold">
                    on your next purchase
                  </Box>{' '}
                  from any participating HVAB Member Business
                </Text>
              </Stack>

              <Formik
                enableReinitialize
                initialValues={{ email: "" }}
                onSubmit={(values, actions) => {

                  actions.setSubmitting(false);
                }}
              >
                {({ values, handleChange, handleSubmit, isSubmitting }) => {
                  return (
                    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                      <Field
                        name="email"
                        validate={validateEmail}
                        width={"100%"}
                      >
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.email && form.touched.email
                            }
                            mb="24px"
                          >
                            <FormLabel htmlFor="email" ms="4px" fontSize="sm" fontWeight="normal">
                              Email
                            </FormLabel>
                            <Input
                              {...field}
                              id="email"
                              borderRadius="15px"
                              fontSize="sm"
                              type="text"
                              placeholder="Your email address"
                              size="lg"
                              value={values.email}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {form.errors.email}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Button
                        fontSize="10px"
                        type="submit"
                        bg="blue.700"
                        w="100%"
                        h="45"
                        mb="20px"
                        color="white"
                        mt="20px"
                        _hover={{
                          bg: "blue.400",
                        }}
                        _active={{
                          bg: "blue.500",
                        }}
                        isLoading={isSubmitting}
                      >
                        GET DISCOUNT!
                      </Button>
                    </form>
                  );
                }}
              </Formik>

              <Link
                fontSize="sm"
                textAlign="center"
                color={useColorModeValue('gray.600', 'gray.400')}
                textDecoration="underline"
                onClick={onModalClose}
              >
                No, I don’t want discounts
              </Link>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Drawer
        isOpen={isFilterOpen}
        size="md"
        placement="left"
        initialFocusRef={firstField}
        closeOnOverlayClick={true}
        onClose={onFilterClose}
      >
        <DrawerOverlay />

        <Formik
          enableReinitialize
          initialValues={{ name: "", experience: null, activity: null, category: null }}
          onReset={(values, actions) => {
            getDestinations();
            handleReset();
            onFilterClose();
          }}
          onSubmit={(values, actions) => {
            if (values.experience) {
              values.experiences = [values.experience];
            }
            if (values.activity) {
              values.activities = [values.activity];
            }
            if (values.category) {
              values.categories = [values.category];
            }
            setSearchValues(values);
            getDestinations(values);
            onFilterClose();
            actions.setSubmitting(false);
          }}
        >
          {({ values, handleChange, handleSubmit, handleReset, isSubmitting, setFieldValue }) => {
            return (
              <form id="searchForm" autoComplete="off" onSubmit={handleSubmit} onReset={handleReset} onChange={handleChange} style={{ width: "100%" }}>
                <DrawerContent>
                  <DrawerCloseButton />
                  <DrawerHeader borderBottomWidth="1px">
                    <Heading pb={0}>Filter</Heading>
                  </DrawerHeader>

                  <DrawerBody>
                    <Box>
                    <Field
                      name="category"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl>
                          <FormLabel>Category</FormLabel>
                          {experiencesloading ? (
                            <Select placeholder="All"></Select>
                          ) : (
                              <Select  {...field}
                                ref={firstField}
                                id="category"
                                borderRadius="15px"
                                fontSize="sm"
                                type="text"
                                placeholder="All"
                                size="lg"
                                value={values.category}
                                onChange={handleChange}>
                                {categories?.map((cat, index) => (
                                  <option key={index} value={cat.id}>{cat.name}</option>
                                ))
                                }
                              </Select>
                            )}
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="experience"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl>
                          <FormLabel mt={2}>Experience</FormLabel>
                          {experiencesloading ? (
                            <Select placeholder="All"></Select>
                          ) : (
                              <Select  {...field}
                                id="experience"
                                borderRadius="15px"
                                fontSize="sm"
                                type="text"
                                placeholder="All"
                                size="lg"
                                value={values.experience}
                                onChange={handleChange}>
                                {experiences?.map((experience, index) => (
                                  <option key={index} value={experience.id}>{experience.name}</option>
                                ))
                                }
                              </Select>
                            )}
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="activity"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl>
                          <FormLabel mt={2}>Activity</FormLabel>
                          {activitiesloading ? (
                            <Select placeholder="All"></Select>
                          ) : (
                              <Select  {...field}
                                id="activity"
                                borderRadius="15px"
                                fontSize="sm"
                                type="text"
                                placeholder="All"
                                size="lg"
                                value={values.activity}
                                onChange={handleChange}>
                                {activities?.map((activity, index) => (
                                  <option key={index} value={activity.id}>{activity.name}</option>
                                ))
                                }
                              </Select>
                            )}
                        </FormControl>
                      )}
                    </Field>

                    <Field
                      name="name"
                      width={"100%"}
                    >
                      {({ field, form }) => (
                        <FormControl>
                          <FormLabel mt={2}>Destination Name</FormLabel>
                          <Input
                            {...field}
                            id="name"
                            borderRadius="15px"
                            fontSize="sm"
                            type="text"
                            placeholder='Penn State'
                            size="lg"
                            value={values.name}
                            onChange={handleChange}
                          />
                        </FormControl>
                      )}
                    </Field>
                    </Box>
                    <Box height="70vh">

                    </Box>
                  </DrawerBody>

                  <DrawerFooter borderTopWidth="1px" justifyContent="flex-end">
                    <Button mr={3} onClick={onFilterClose}>
                      Close
                    </Button>
                    <Button mr={3} type="reset">Reset</Button>
                    <Button mr={3} type="submit" isLoading={isSubmitting}>Search</Button>
                  </DrawerFooter>
                </DrawerContent>
              </form>
            );
          }}
        </Formik>
      </Drawer>

    </PageSlideFade>
  );
};

export default Destinations;
